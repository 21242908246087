import React from "react";
import clx from "classnames";
import styles from "./index.module.scss";
import { CustomCollapse } from "..";
import {
  DARK_SKELETON_BG,
  DARK_SKELETON_FG,
  DARK_THEME,
  LIGHT_SKELETON_BG,
  LIGHT_SKELETON_FG,
} from "../../constants";
import { PLACEHOLDER_IMAGE_URL, useImage } from "../../hooks/useImage";
import ContentLoader from "react-content-loader";
import { useStore } from "../../store";
import { useRef } from "react";
import { OperationDescription } from "../../interfaces/common.interfaces";

interface DescriptionWithImageProps {
  isOpen: boolean;
  toggleDetails: () => void;
  collapseChildren: React.ReactNode;
  description: OperationDescription;
  loading?: boolean;
  hideDetails?: boolean;
}

export const DescriptionWithImage = ({
  isOpen,
  toggleDetails,
  collapseChildren,
  description,
  loading = false,
  hideDetails = false,
}: DescriptionWithImageProps) => {
  const isMobileApp = useStore((state) => state.isMobileApp);
  const appTheme = useStore((state) => state.appTheme);
  const imgRef = useRef(null);
  const { onImageError, onImageLoad, isImageLoading } = useImage(imgRef);

  return (
    <div
      className={clx(
        styles.description_item_container,
        isMobileApp && styles.in_mobile_cont,
        appTheme === DARK_THEME && styles.dark_mode_description_item_container
      )}
    >
      <div
        className={clx(
          styles.lambda_item_img,
          isMobileApp && styles.in_mobile_img
        )}
      >
        {" "}
        {(isImageLoading || loading) && (
          <ContentLoader
            speed={2}
            viewBox="0 0 122 122"
            backgroundColor={
              appTheme === DARK_THEME ? DARK_SKELETON_BG : LIGHT_SKELETON_BG
            }
            foregroundColor={
              appTheme === DARK_THEME ? DARK_SKELETON_FG : LIGHT_SKELETON_FG
            }
          >
            <rect x="0" y="0" rx="6" ry="6" width="122" height="110" />
          </ContentLoader>
        )}
        {!loading && (
          <img
            ref={imgRef}
            src={description.image ? description.image : PLACEHOLDER_IMAGE_URL}
            onError={onImageError}
            onLoad={onImageLoad}
            alt="description"
          />
        )}
      </div>
      {!hideDetails && (
        <>
          <div className={styles.description_details}>
            <div
              id="details_toggler"
              className={clx(styles.details_toggle, isOpen && styles.open)}
              onClick={toggleDetails}
            >
              {isOpen ? "Hide" : "Details"}
            </div>
          </div>
          <CustomCollapse
            isOpen={isOpen}
            onClick={toggleDetails}
            children={
              <>
                <div className={styles.description_details_container}>
                  <hr />
                  {collapseChildren}
                </div>
              </>
            }
          />
        </>
      )}
    </div>
  );
};
